import React, { useState } from 'react'
import { Region } from 'src/interfaces/demographics'
import { Button, Typography, Menu, MenuItem, Divider } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTheme } from '@emotion/react'

import { STATEINITIALS } from '../../../constants/demographics'
import DisabledTooltip from 'src/components/disabledTooltip/DisabledTooltip'

const GoToMetroButton = React.memo(
  ({
    handleSearchRegionChange,
    region,
    subregionsData,
    loadingDropdown
  }: {
    handleSearchRegionChange: Function
    region: Region
    subregionsData: Region[]
    loadingDropdown: Boolean
  }) => {
    const theme = useTheme()
    const [anchorMetroMenu, setAnchorMetroMenu] = useState<Element | null>(null)

    const open = !!anchorMetroMenu

    const handleClose = () => {
      setAnchorMetroMenu(null)
    }

    const handleMetroMenuClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      setAnchorMetroMenu(event.currentTarget as Element)
    }

    const stateInitials =
      STATEINITIALS[
        region.region_name.replace(/ /g, '_') as keyof typeof STATEINITIALS
      ]

    return (
      <>
        <DisabledTooltip
          title='No Metro Found'
          disabled={subregionsData.length <= 0 && !loadingDropdown}
        >
          <Button
            variant='outlined'
            onClick={handleMetroMenuClick}
            sx={{
              width: '102%',
              textTransform: 'none',
              color: theme.palette.neutral.gray900,
              border: '2px solid #E5E7EB',
              borderRadius: '120px',
              fontFamily: 'DM Sans'
            }}
            disabled={subregionsData.length <= 0 && !loadingDropdown}
          >
            {loadingDropdown ? (
              <Typography variant='bodyBold'>Loading...</Typography>
            ) : (
              <Typography variant='bodyBold'>{stateInitials} metros</Typography>
            )}
            <ExpandMoreIcon />
          </Button>
        </DisabledTooltip>
        <Menu
          id='metroMenu'
          anchorEl={anchorMetroMenu}
          open={open}
          onClose={handleClose}
        >
          {loadingDropdown ? (
            <MenuItem
              sx={{
                color: theme.palette.neutral.gray700,
                pointerEvents: 'none'
              }}
            >
              Loading...
            </MenuItem>
          ) : subregionsData.length > 0 ? (
            subregionsData
              .map((subregion, index) => [
                <MenuItem
                  key={`subregion_menuitem_${index}`}
                  onClick={(event) =>
                    handleSearchRegionChange(event, subregion)
                  }
                  sx={{ typography: 'body' }}
                >
                  {subregion.region_name}
                </MenuItem>,
                index === subregionsData.length - 1 && <Divider key='divider' />
              ])
              .concat([
                <MenuItem
                  key='info'
                  sx={{
                    color: theme.palette.neutral.gray700,
                    pointerEvents: 'none'
                  }}
                >
                  Some metro areas may not be listed
                </MenuItem>
              ])
          ) : (
            <MenuItem
              sx={{
                color: theme.palette.neutral.gray700,
                pointerEvents: 'none'
              }}
            >
              No metros found
            </MenuItem>
          )}
        </Menu>
      </>
    )
  }
)

export default GoToMetroButton
