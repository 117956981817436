import { Navigate } from 'react-router-dom'

import { useTheme } from '@emotion/react'
import { useAuth0 } from '@auth0/auth0-react'

import LoginCreateAccount from '../components/login/LoginCreateAccount'

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Link,
  Paper,
  Typography
} from '@mui/material'

import { CssVarsProvider, extendTheme } from '@mui/joy/styles'
import Tabs from '@mui/joy/Tabs'
import TabPanel from '@mui/joy/TabPanel'
import TabList from '@mui/joy/TabList'
import Tab, { tabClasses } from '@mui/joy/Tab'
import { useDocumentTitle } from 'src/hooks/useDocumentTitle'

function HomePage() {
  const theme = useTheme()
  const { isLoading, isAuthenticated } = useAuth0()
  useDocumentTitle('Homepage - StarSight')

  if (!isLoading && isAuthenticated) {
    return <Navigate to='/find-stars' replace />
  } else {
    localStorage.removeItem('gtmUserLogin')
    localStorage.removeItem('redirect')
    window.dataLayer.push({ event: 'Page Loaded' })
  }

  const joyType = {
    'title-lg': theme.typography.subheader,
    'title-md': theme.typography.subheader,
    'title-sm': theme.typography.subheader
  }

  const joyTheme = extendTheme({
    fontFamily: {
      display: theme.typography.fontFamily,
      body: theme.typography.fontFamily
    },
    typography: { ...joyType, ...theme.typography }
  })

  const tabPanelCopy = [
    [
      {
        image: '/employer1_explore.jpg',
        image_title: 'employer explore',
        card_title: 'Explore STARs',
        card_list: [
          'Find STARs in your region',
          'See demographic and skills diversity',
          'Locate where STARs work today'
        ]
      },
      {
        image: '/employer2_compare.jpg',
        image_title: 'employer compare',
        card_title: 'Compare skills',
        card_list: [
          'Find jobs where STARs excel',
          'See pathways for upward mobility',
          'Identify transferable skills'
        ]
      },
      {
        image: '/employer3_assess.jpg',
        image_title: 'employer assess',
        card_title: 'Assess job requirements',
        card_list: [
          'Quantify the impact of degree requirements',
          'See skills needed for your roles',
          'Build the case for hiring STARs'
        ]
      }
    ],
    [
      {
        image: '/policy1_explore.jpg',
        image_title: 'policy explore',
        card_title: 'Explore STARs',
        card_list: [
          'Find STARs in your community',
          'Compare STARs population across regions',
          'See demographic and skills diversity'
        ]
      },
      {
        image: '/policy2_compare.jpg',
        image_title: 'policy compare',
        card_title: 'Compare pathways',
        card_list: [
          'See possibilities for upward mobility across industries',
          'Explore racial diversity across job pathways'
        ]
      },
      {
        image: '/policy3_assess.png',
        image_title: 'policy assess',
        card_title: "Assess STARs' impact",
        card_list: [
          'Quantify the talent pool for different worker profiles',
          'Build the case for STARs workforce policy'
        ]
      }
    ]
  ]

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              backgroundImage: "url('marketing_feature_debrena@2x.jpg')",
              backgroundPosition: 'top',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              maxHeight: 685,
              minHeight: 685
            }}
          >
            <Container sx={{ position: 'relative', minHeight: 685 }}>
              <Grid container>
                <Grid item xs={8} sx={{ pt: 11 }}>
                  <Typography
                    variant='display'
                    sx={{
                      color: theme.palette.neutral.white,
                      fontFamily: theme.typography.fontFamily
                    }}
                  >
                    Look to STARs to level up your workforce.
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ position: 'absolute', bottom: 0, right: 0, mb: 8 }}>
                <Typography
                  variant='subheader'
                  sx={{
                    color: theme.palette.neutral.white,
                    fontFamily: theme.typography.fontFamily
                  }}
                >
                  Debrena is a STAR*
                </Typography>
              </Box>
            </Container>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ pt: 6, pb: 8, backgroundColor: theme.palette.secondary.main }}
      >
        <Grid item xs={12}>
          <Container>
            <Box sx={{ width: 800 }}>
              <Typography
                variant='subheader'
                sx={{ fontFamily: theme.typography.fontFamily }}
              >
                The insights you need to unlock half of U.S. workers are at your
                fingertips.
                <br />
              </Typography>

              <Typography
                variant='subheader'
                sx={{
                  fontWeight: 400,
                  fontFamily: theme.typography.fontFamily
                }}
              >
                <br />
                StarSight aggregates the latest national labor market data for
                the 70M+ skilled workers who are STARs, providing a complete
                picture of regional talent pools. Organizations and communities
                can more confidently build an inclusive workforce strategy that
                meets today’s talent demands and provides greater upward
                mobility.
              </Typography>
            </Box>
          </Container>
        </Grid>
      </Grid>
      <Grid container sx={{ pt: 5, pb: 5 }}>
        <Grid item xs={12}>
          <Container>
            <Box>
              <CssVarsProvider theme={joyTheme}>
                <Tabs
                  aria-label='tabs'
                  defaultValue={0}
                  sx={{ bgcolor: 'transparent' }}
                >
                  <TabList
                    disableUnderline
                    tabFlex={1}
                    sx={{
                      p: 0.5,
                      gap: 0.5,
                      borderRadius: 24,
                      bgcolor: 'background.level1',
                      [`& .${tabClasses.root}[aria-selected="true"]`]: {
                        boxShadow: 'sm',
                        bgcolor: 'background.surface',
                        color: theme.palette.neutral.gray900
                      },
                      [`& .${tabClasses.root}[aria-selected="false"]`]: {
                        color: theme.palette.neutral.gray700
                      },
                      [`& .${tabClasses.root}`]: {
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 600,
                        fontSize: '21px'
                      }
                    }}
                  >
                    <Tab disableIndicator>For Employers</Tab>
                    <Tab disableIndicator>For Policy Makers</Tab>
                  </TabList>

                  {tabPanelCopy.map((tabpanel, index) => {
                    return (
                      <TabPanel value={index} key={index} sx={{ p: 0, pt: 2 }}>
                        <Grid container spacing={3}>
                          {tabpanel.map((card, cardindex) => {
                            return (
                              <Grid item xs={4} key={cardindex}>
                                <Card
                                  elevation={400}
                                  sx={{
                                    pl: 2,
                                    pr: 2,
                                    pt: 2,
                                    minHeight: 439,
                                    borderRadius: 16
                                  }}
                                >
                                  <CardMedia
                                    sx={{
                                      height: 240,
                                      border: 1,
                                      borderColor:
                                        theme.palette.neutral.gray300,
                                      borderRadius: 4
                                    }}
                                    image={card.image}
                                    title={card.image_title}
                                  />
                                  <CardContent sx={{ pl: 0 }}>
                                    <Typography
                                      gutterBottom
                                      variant='subheader'
                                      component='div'
                                    >
                                      {card.card_title}
                                    </Typography>
                                    <Typography variant='body'>
                                      <ul style={{ paddingLeft: 16 }}>
                                        {card.card_list.map(
                                          (listItem, liindex) => {
                                            return (
                                              <li key={liindex}>{listItem}</li>
                                            )
                                          }
                                        )}
                                      </ul>
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                            )
                          })}
                        </Grid>
                      </TabPanel>
                    )
                  })}
                </Tabs>
              </CssVarsProvider>
            </Box>
          </Container>
        </Grid>
      </Grid>
      <Box sx={{ pt: 6, pb: 8, backgroundColor: theme.palette.secondary.main }}>
        <Container>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <Typography
                variant='subheader'
                sx={{ fontFamily: theme.typography.fontFamily }}
              >
                STARs are workers Skilled Through Alternative Routes.
                <br />
              </Typography>
              <Typography
                variant='body'
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  color: theme.palette.neutral.gray900
                }}
              >
                <br />
                STARs have built valuable skills through alternative routes such
                as on-the-job training, military service, training bootcamps,
                and community college, instead of a bachelor’s degree. They
                represent half of the U.S. workforce – a massive pool of talent
                that frequently gets overlooked.
                <br />
              </Typography>

              <Typography
                variant='body'
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  color: theme.palette.neutral.gray900
                }}
              >
                <br />
                STARs have the skills employers need and represent the diversity
                of the country, yet are often blocked from higher wage jobs by
                degree screens and other barriers that make up the{' '}
                <Link
                  href='https://www.tearthepaperceiling.org/'
                  variant='body'
                  underline='always'
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    color: theme.palette.neutral.gray900,
                    textDecorationColor: theme.palette.neutral.gray900
                  }}
                >
                  paper ceiling
                </Link>
                .
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ minWidth: 0, p: 1, boxShadow: 0, borderRadius: 4 }}>
                <Box sx={{ borderRadius: 4, overflow: 'hidden', height: 315 }}>
                  <iframe
                    title='home_page_video'
                    width='100%'
                    height='315'
                    src='https://www.youtube.com/embed/EeliTEmbFlQ?controls=0'
                    frameBorder='0'
                    allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  ></iframe>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container sx={{ mt: 6, mb: 6 }}>
        <Grid
          container
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Grid item xs={12}>
            <Typography
              variant='subheader'
              sx={{ fontFamily: theme.typography.fontFamily }}
            >
              Take the first step to build inclusion into your talent strategy
              or workforce policy.
            </Typography>
          </Grid>
          <Grid
            container
            direction='column'
            justifyContent='flex-end'
            alignItems='flex-end'
            sx={{ pt: 2 }}
          >
            <Grid item xs={12}>
              <LoginCreateAccount footer={true} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default HomePage
