import { Tooltip } from '@mui/material'
import { useState } from 'react'

const DisabledTooltip = ({ children, title, disabled }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      onMouseEnter={() => disabled && setIsHovered(true)}
      onMouseLeave={() => disabled && setIsHovered(false)}
      style={{ width: 'fit-content' }}
    >
      <Tooltip open={disabled && isHovered} title={title} arrow>
        {children}
      </Tooltip>
    </div>
  )
}

export default DisabledTooltip
